import {focusElement, hookToAttributeSelector} from '@wix/panda-js-utils'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {KeyboardEventHandler, useEffect, useState} from 'react'
import {usePrevious} from '../../../../../../commons/hooks/use-previous'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import {EventImage} from '../../event-image'
import s from './card.scss'
import {Content} from './components/content'
import {Overlay} from './components/overlay'
import {CardProps, CardWrapperProps} from './interfaces'

export const Card = ({event, hasRibbon, itemOpened, listItem}: CardProps) => {
  const [expanded, setExpanded] = useState(false)
  const prevExpanded = usePrevious(expanded)
  const {isEditor} = useEnvironment()
  const {
    allBreakpoints: {isListImageEnabled},
    currentBreakpoint: {isListAdditionalComponentsVisible},
  } = useVisibilityStyles()

  const showOverlay = expanded || (isEditor && itemOpened && isListAdditionalComponentsVisible())

  const toggleOverlay = () => setExpanded(prev => !prev)

  useEffect(() => {
    if (prevExpanded && !expanded) {
      focusElement({selector: hookToAttributeSelector(DH.moreInfoLink(event.id)), canFocus: true})
    }
  }, [expanded])

  const handleEscPress: KeyboardEventHandler = e => {
    if (e.key === 'Escape' && expanded) {
      toggleOverlay()
    }
  }

  return (
    <CardWrapper onEscPress={handleEscPress} listItem={listItem}>
      <div className={classNames(s.container, {[s.hideElements]: showOverlay})}>
        {isListImageEnabled() && (
          <div className={s.imageContainer}>
            <EventImage event={event} opacityFallback backgroundFallback className={s.image} />
          </div>
        )}
        <Content event={event} toggleOverlay={toggleOverlay} hasRibbon={hasRibbon} />
      </div>
      {showOverlay && <Overlay event={event} toggleOverlay={toggleOverlay} hasRibbon={hasRibbon} />}
    </CardWrapper>
  )
}

const CardWrapper = ({listItem, onEscPress, children}: CardWrapperProps) => {
  const props = {
    onKeyDown: onEscPress,
    className: s.root,
    'data-hook': DH.card,
  }

  return listItem ? <li {...props}>{children}</li> : <div {...props}>{children}</div>
}
