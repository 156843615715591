import React from 'react'
import {useWidgetState} from '../../../hooks/state-provider'
import {getEvents} from '../../../selectors/events'
import {Card} from '../card'
import s from './featured-card.scss'

export const FeaturedCard = () => {
  const [featuredEvent] = useWidgetState(getEvents)

  return (
    <div className={s.container}>
      <Card event={featuredEvent} />
    </div>
  )
}
